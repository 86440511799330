import { Row } from 'emotion-flex';
import React, { FC } from 'react';
import Container from '../Reimbursement/Container';
import {
  MonoFontLabel,
  OpenSansParagraph,
  RecoletaHeading,
} from '../common/typography';
import { markdownText } from '../../pages/doctors';
import Knowledge from '../../assets/svgs/product/img-module-knowledge.svg';
import Emotions from '../../assets/svgs/product/img-module-emotions.svg';
import Nutrition from '../../assets/svgs/product/img-module-nutrition.svg';
import Hypnosis from '../../assets/svgs/product/img-module-hypnosis.svg';
import { useIntl } from 'react-intl';

interface CardProps {
  prefix: string;
  title: string;
  description: string;
  color: string;
  src: string;
}

const Card: FC<CardProps> = ({ prefix, title, description, color, src }) => {
  return (
    <div
      css={{
        width: 500,
        minHeight: 400,
        height: 'auto',
        backgroundColor: color,
        padding: '1rem',
        borderRadius: '1rem',
        margin: '.5rem',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <MonoFontLabel
            css={{
              fontWeight: 700,
              letterSpacing: '2px',
              lineHeight: '18px',
              color: '#00000080',
              marginBottom: 0,
            }}
          >
            {prefix}
          </MonoFontLabel>
          <RecoletaHeading
            css={{
              fontSize: '24px',
              fontWeight: 600,
              maxWidth: 400,
            }}
          >
            {title}
          </RecoletaHeading>
        </div>
        <img src={src} alt={title} />
      </div>

      <OpenSansParagraph
        css={{
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '25px',
          marginTop: '1rem',
          width: '100%',
        }}
      >
        {markdownText(description)}
      </OpenSansParagraph>
    </div>
  );
};

const PersonalizedContent: FC = () => {
  const intl = useIntl();

  const dataObj = [
    {
      prefix: intl.formatMessage({
        id: 'product.page.personalised.content.item1.prefix',
        defaultMessage: 'Pflichtmodul:',
      }),
      title: intl.formatMessage({
        id: 'product.page.personalised.content.item1.title',
        defaultMessage: 'Basiswissen',
      }),
      description: intl.formatMessage({
        id: 'product.page.personalised.content.item1.text',
        defaultMessage:
          'Werde zur Expertin bzw. zum Experten des Reizdarmsyndroms und lege so die Basis für deine erfolgreiche Therapie.\n\nLerne mehr über die Ursachen, die Entstehung, die Diagnostik sowie Therapiemöglichkeiten des Reizdarmsyndroms.',
      }),
      color: '#DDF2F8',
      src: Knowledge,
    },
    {
      prefix: intl.formatMessage({
        id: 'product.page.personalised.content.item2.prefix',
        defaultMessage: 'optionales Modul:',
      }),
      title: intl.formatMessage({
        id: 'product.page.personalised.content.item2.title',
        defaultMessage: 'Gefühle & Verhalten',
      }),
      description: intl.formatMessage({
        id: 'product.page.personalised.content.item2.text',
        defaultMessage:
          'Entwickle Strategien, um den negativen Einfluss hinderlicher Gedanken, Gefühle und Verhaltensweisen auf deine Psyche und dein körperliches Wohlbefinden zu reduzieren.\n\nIm Rahmen des Moduls werden dir zusätzlich ein Gedanken- und Schlaftagebuch sowie angeleitete Entspannungsübungen zur Verfügung gestellt.',
      }),
      color: '#FBE5EF',
      src: Emotions,
    },
    {
      prefix: intl.formatMessage({
        id: 'product.page.personalised.content.item3.prefix',
        defaultMessage: 'optionales Modul:',
      }),
      title: intl.formatMessage({
        id: 'product.page.personalised.content.item3.title',
        defaultMessage: 'Ernährung',
      }),
      description: intl.formatMessage({
        id: 'product.page.personalised.content.item3.text',
        defaultMessage:
          'Identifiziere im Rahmen der Low-FODMAP-Diät deine persönlichen Symptomauslöser und finde Schritt für Schritt eine verträgliche Dauerernährung.\n\nZusätzlich erhältst du personalisierte Empfehlungen zum Umgang mit bestehenden Allergien und Intoleranzen sowie eine auf dich angepasste Lebensmittelliste.',
      }),
      color: '#FFF8CC',
      src: Nutrition,
    },
    {
      prefix: intl.formatMessage({
        id: 'product.page.personalised.content.item4.prefix',
        defaultMessage: 'optionales Modul:',
      }),
      title: intl.formatMessage({
        id: 'product.page.personalised.content.item4.title',
        defaultMessage: 'Audio-geführte Hypnose',
      }),
      description: intl.formatMessage({
        id: 'product.page.personalised.content.item4.text',
        defaultMessage:
          'Ziel der darmgerichteten Hypnose ist es die Darm-Hirn-Achse durch Suggestionen positiv zu beeinflussen und so deine Beschwerden durch Entspannung zu lindern.\n\nLerne die Grundlagen der Hypnose kennen, werde durch Übungen unterstützt und führe regelmäßig audio-geführte Hypnosen durch.',
      }),
      color: '#E9DDEC',
      src: Hypnosis,
    },
  ];

  return (
    <Container css={{ maxWidth: 1200, marginTop: '2rem' }}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <RecoletaHeading
          css={{
            fontSize: '32px',
            fontWeight: 500,
            lineHeight: '38px',
            textAlign: 'center',
          }}
        >
          {markdownText(
            intl.formatMessage({
              id: "product.page.personalised.content.title",
              defaultMessage:
                "<b style='color: #2AAB3C'>Personalisierte Inhalte</b><br/>unseres Programms",
            })
          )}
        </RecoletaHeading>
        <OpenSansParagraph
          css={{
            textAlign: 'center',
            marginBottom: '1rem',
            maxWidth: 600,
          }}
        >
          {markdownText(
            intl.formatMessage({
              id: "product.page.personalised.content.text",
              defaultMessage:
                "Nach Auswertung deines Fragebogens erhältst du eine <b>auf dich zugeschnittene Kombination</b> der folgenden Module:",
            })
          )}
        </OpenSansParagraph>
      </div>
      <Row
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {dataObj.map((data, index) => (
          <Card key={index} {...data} />
        ))}
      </Row>
    </Container>
  );
};

export default PersonalizedContent;
